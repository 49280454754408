import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ServicePostTwoData } from "@/data";
import ServiceCardTwo from "@/components/service-card-two";
import { GiBoxUnpacking, GiCargoShip, GiHandTruck, GiPathDistance, GiTruck } from "react-icons/gi";

let posts = [
  {
    title: "Logistics Solutions",
    icon: <GiHandTruck />,
    iconName: "mei-computer-graphic",
    url: "/service-details",
  },
  {
    title: "Import and Export",
    icon: <GiTruck />,
    iconName: "mei-computer-graphic",
    url: "/service-details",
  },
  {
    title: "Supplier and Distributor",
    icon: <GiPathDistance />,
    iconName: "mei-development-1",
    url: "/service-details",
  },
  {
    title: "Wholesaler and Exporter",
    icon:<GiCargoShip/>,
    iconName: "mei-development",
    url: "/service-details",
  },
  {
    title: "Products Procurement",
    icon:<GiBoxUnpacking />,
    iconName: "mei-app-development",
    url: "/service-details",
  },
]

const ServiceTwo = () => {
  const { sectionContent } = ServicePostTwoData;
  const { title, subTitle, text } = sectionContent;
  return (
    <section
    id='service'
    className="service_section commonSection">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h4 className="sub_title red_color">{subTitle}</h4>
            <h2 className="sec_title white">{title}</h2>
            <p className="sec_desc color_aaa">{text}</p>
          </Col>
        </Row>
        <Row className="custom_column">
          {posts.map((data, index) => (
            <Col key={index} lg={3} md={6} sm={12}>
              <ServiceCardTwo data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServiceTwo;
